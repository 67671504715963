@import "styles/globalVariables/index.scss";

.contacts-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--third-bg-color);
  display: flex;
  justify-content: center;
  overflow: hidden;
  .contacts {
    width: 80%;
    .under-title {
      @include under-title;
      color: var(--third-text-color);
      .title {
        @include title;
      }
    }
    .content {
      display: flex;
      margin-top: 40px;
      height: calc(100% - 240px);
      @media screen and (max-width: 780px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .left-side {
        width: 50%;
        text-align: center;
        .contacts-illustration {
          width: 80%;
          height: 80%;
          filter: drop-shadow(-4px 4px 6px rgba(0, 0, 0, 0.3));
        }
      }
      .right-side {
        @include scroll-styles;
        width: 50%;
        height: 90%;
        overflow-y: auto;
        text-overflow: ellipsis;
        padding-left: 30px;
        @media screen and (max-width: 780px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
