@font-face {
  font-family: "Glass_TTY";
  src: url("/assets/fonts/Glass_TTY_VT220.ttf");
}
@import "styles/globalVariables/index.scss";

.home-wrapper {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  height: 100vh;
  background: var(--home-gradient-bg);
  .home {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    background: url("../../assets/images/airplanes.svg") no-repeat;
    background-size: 65vh;
    background-position: 10% 85%;
    .middle {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      .left-side {
        width: 50%;
        @media (max-width: $tablet-width) {
          width: 100%;
        }
        .company-name {
          font-weight: 600;
          font-size: 100%;
          font-size: 3vw;
          color: var(--second-text-color);
          @media (max-width: $tablet-width) {
            font-size: 6vw;
          }
        }
        .company-slogan {
          display: flex;
          align-items: center;
          .terminal {
            position: absolute;
            input {
              font-family: "Glass_TTY";
              font-size: 24px;
              color: rgba(255, 255, 255, 0);
              letter-spacing: 0.2em;
              padding: 0;
              border: none;
              z-index: 1;
              width: 100%;
            }
          }
          .MuiOutlinedInput-notchedOutline {
            outline: none;
            border-width: 0;
          }
          .MuiFormControl-root {
            width: 300px;
            @media (max-width: $tablet-width) {
              width: 250px;
            }
          }
          .slogan-text {
            font-family: "Glass_TTY";
            font-size: 24px;
            color: var(--hover-btn-color);
            letter-spacing: 0.2em;
            z-index: 0;
            pointer-events: none;
            .cursor {
              animation: cursor 1s infinite;
            }
          }
          .second-terminal {
            pointer-events: none;
            .terminal-text {
              white-space: nowrap;
              overflow: hidden;
              width: 300px;
              margin-left: 5px;
              text-overflow: ellipsis;
              @media (max-width: $mobile-width) {
                width: 120px;
              }
            }
          }
        }
      }
      .right-side {
        .main-illustration {
          width: 100%;
          height: 70vh;
        }
        @media (max-width: $tablet-width) {
          display: none;
        }
      }
    }
    .arrow-down {
      position: absolute;
      bottom: 35px;
      animation: arrow-down 2s infinite;
      @media (max-width: $tablet-width) {
        bottom: 15px;
      }
      .link {
        cursor: pointer;
      }
    }
  }
}

@keyframes cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes arrow-down {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(20px) scale(0.8);
  }
  100% {
    transform: translateY(0);
  }
}
