@import "styles/globalVariables/index.scss";

.footer-wrapper {
  width: 100%;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--home-gradient-bg);
  .footer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: var(--second-text-color);
    @media (max-width: $tablet-width) {
      flex-direction: column;
    }
    .left-side {
      width: 33%;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: $tablet-width) {
        width: 100%;
        justify-content: center;
      }
      .social {
        margin: 0 20px 5px 0;
        .social-logo {
          width: 30px;
          transition: all 300ms;
          &:hover {
            transform: scale(1.2) translateY(-5px);
            filter: drop-shadow(0 5px 5px rgba(22, 21, 104, 1));
          }
        }
      }
    }
    .middle {
      text-align: center;
      width: 33%;
      @media (max-width: $tablet-width) {
        width: 100%;
      }
    }
    .right-side {
      width: 33%;
      @media (max-width: $tablet-width) {
        display: none;
      }
    }
  }
}
