@import "styles/globalVariables/index.scss";

.MuiTabs-root {
  width: 80%;
  @media (max-width: $tablet-width) {
    width: 100%;
  }
}
.projects-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--second-bg-color);
  display: flex;
  justify-content: center;
  overflow: hidden;
  .projects {
    width: 80%;
    .under-title {
      @include under-title;
      color: var(--second-text-color);
      .title {
        @include title;
      }
    }
    .content {
      display: flex;
      margin-top: 40px;
      height: calc(100% - 240px);
      @media (max-width: $tablet-width) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
      }
      .left-side {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        @media (max-width: $tablet-width) {
          width: 100%;
        }
        .tabs {
          height: 570px;
          padding: 40px;
          @media (max-width: $tablet-width) {
            height: 540px;
            padding: 0;
          }
          @media screen and (min-width: $four-k) {
            height: 1040px;
          }
          .tab-content {
            position: relative;
            margin-bottom: 10px;
            border: 2px var(--third-bg-color) solid;
            box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.5);
            border-radius: 8px;
            margin: 10px;
            transition: all 300ms;
            .side-l {
              object-fit: contain;
              height: 100px;
              @media (max-width: $tablet-width) {
                height: 100px;
              }
              @media screen and (min-width: $four-k) {
                height: 200px;
              }
              img {
                border-radius: 8px;
                height: 100px;
                width: 100%;
                object-fit: cover;
                @media screen and (min-width: $four-k) {
                  height: 200px;
                }
              }
            }
            .side-r {
              border-radius: 0 7px 7px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              top: 0;
              right: 0;
              position: absolute;
              color: var(--main-text-color);
              font-size: 16px;
              font-weight: 600;
              text-transform: uppercase;
              background-color: rgba(255, 255, 255, 0.95);
              height: 100%;
              width: 150px;
              overflow: hidden;
              white-space: break-spaces;
              text-align: center;
              @media screen and (min-width: $four-k) {
                height: 200px;
              }
            }
            &:hover {
              transform: scale(1.03) translateY(-10px);
              @media (max-width: $tablet-width) {
                transform: scale(1.01) translateY(-10px);
              }
              @media screen and (min-width: $four-k) {
                transform: scale(1.01) translateY(-10px);
              }
            }
          }
          .MuiButtonBase-root {
            transform: scale(2);
            margin: 20px 0 20px 0;
            height: 20px;
          }
          .MuiSvgIcon-root {
            fill: var(--main-text-color);
          }
        }
      }
      .right-side {
        width: 50%;
        text-align: center;
        @media (max-width: $tablet-width) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .projects-illustration {
          width: 80%;
          height: 80%;
          filter: drop-shadow(-4px 4px 6px rgba(0, 0, 0, 0.3));
        }
      }
    }
  }
}
